import React from 'react'

import Gallery from '../../components/common/gallery'

const Cups = () => (
  <Gallery
    imageLinks={[1, 2, 3, 4, 5, 7, 8].map((number) => (
      `https://s3.amazonaws.com/valentina-site/ceramics_images/cups/${number}image.jpg`
    ))}
    navigationColor='#77b9c7'
    fromText='Ceramics'
    fromLink='/ceramics'
  />
)

export default Cups